<template>
  <v-dialog v-model="dialog" width="500">
    <template #activator="{ on, attrs }">
      <v-btn
        color="primary"
        dark
        class="mr-1"
        small
        v-bind="attrs"
        v-on="on"
        @click="openDialog()"
      >
        New site view
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span>Add Site view</span>
      </v-card-title>
      <v-divider />
      <ValidationObserver v-slot="{ invalid }" ref="validator">
        <v-card-text class="pt-5">
          <v-form>
            <ERow>
              <ECol cols="12" class="pt-0 pb-0">
                <Autocomplete
                  v-model="selectedProject"
                  item-value="id"
                  label="Project name"
                  no-filter
                  :cache-items="false"
                  resource="projects"
                  rules="required"
                  dense
                />
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Project id"
                  rules="required"
                >
                  <v-text-field
                    v-model="siteView.projectId"
                    disabled
                    dense
                    outlined
                    :error-messages="errors"
                    label="Project id"
                  />
                </ValidationProvider>
              </ECol>
              <ECol cols="12" class="pt-0 pb-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Viewer Type"
                  rules="required"
                >
                  <v-select
                    v-model="siteView.viewerType"
                    :items="types"
                    label="Viewer Type"
                    dense
                    outlined
                    :error-messages="errors"
                    item-value="value"
                    item-text="name"
                  />
                </ValidationProvider>
              </ECol>
              <ECol v-if="siteView.viewerType" cols="12" class="pt-0 pb-0">
                <ECol
                  v-if="siteView.viewerType == 'Cesium'"
                  cols="12"
                  class="pt-0 pb-0"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Cesium Id"
                    rules="required"
                  >
                    <v-text-field
                      v-model="siteView.cesiumId"
                      dense
                      outlined
                      :error-messages="errors"
                      label="cesium 3D id"
                    />
                  </ValidationProvider>
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Cesium Id 2D"
                    rules="required"
                  >
                    <v-text-field
                      v-model="siteView.cesiumId2D"
                      dense
                      outlined
                      :error-messages="errors"
                      label="cesium 2D id"
                    />
                  </ValidationProvider>
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Tile Quality"
                    rules="required"
                  >
                    <v-text-field
                      v-model="siteView.maximumScreenSpaceError"
                      dense
                      outlined
                      :error-messages="errors"
                      label="Tile Quality"
                    />
                  </ValidationProvider>
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Timeline date"
                    :rules="{
                      date_format: ['DD/MM/YYYY'],
                    }"
                  >
                    <v-text-field
                      v-model="siteView.cesiumDate"
                      dense
                      outlined
                      :error-messages="errors"
                      label="Timeline Date"
                    />
                  </ValidationProvider>
                </ECol>

                <ECol
                  v-else-if="siteView.viewerType == 'iTwin'"
                  cols="12"
                  class="pt-0 pb-0"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Model Name"
                    rules="required"
                  >
                    <v-text-field
                      v-model="siteView.modelName"
                      dense
                      outlined
                      :error-messages="errors"
                      label="Model Name"
                    />
                  </ValidationProvider>
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="iTwin ID"
                    rules="required"
                  >
                    <v-text-field
                      v-model="siteView.iTwinId"
                      dense
                      outlined
                      :error-messages="errors"
                      label="iTwin ID"
                    />
                  </ValidationProvider>

                  <ValidationProvider
                    v-slot="{ errors }"
                    name="iModel ID"
                    rules="required"
                  >
                    <v-text-field
                      v-model="siteView.iModelId"
                      dense
                      outlined
                      :error-messages="errors"
                      label="iModel ID"
                    />
                  </ValidationProvider>
                  <v-text-field
                    v-model="siteView.changeSetId"
                    dense
                    outlined
                    label="changeSet ID"
                  />
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="is iModel"
                    rules="required"
                  >
                    <v-select
                      v-model="siteView.isIModel"
                      :items="[true, false]"
                      label="is iModel"
                      dense
                      outlined
                      :error-messages="errors"
                      item-value="value"
                      item-text="name"
                    />
                  </ValidationProvider>
                  <ECol v-if="siteView.isIModel" cols="12" class="pt-0 pb-0">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Height"
                      rules="required"
                    >
                      <v-text-field
                        v-model="siteView.height"
                        dense
                        outlined
                        :error-messages="errors"
                        label="height"
                        type="number"
                      />
                    </ValidationProvider>
                  </ECol>
                  <ECol
                    v-if="siteView.isIModel === false"
                    cols="12"
                    class="pt-0 pb-0"
                  >
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Cesium Id"
                      rules="required"
                    >
                      <v-text-field
                        v-model="siteView.cesiumId"
                        dense
                        outlined
                        :error-messages="errors"
                        label="cesium id"
                      />
                    </ValidationProvider>
                  </ECol>
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Marker distance threshold"
                    rules="required"
                  >
                    <v-text-field
                      v-model="siteView.markerDistanceThreshold"
                      type="number"
                      dense
                      outlined
                      :error-messages="errors"
                      label="Marker distance threshold"
                    />
                  </ValidationProvider>
                </ECol>

                <ECol
                  v-else-if="siteView.viewerType == 'Forge'"
                  cols="12"
                  class="pt-0 pb-0"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="URN"
                    rules="required"
                  >
                    <v-text-field
                      v-model="siteView.urn"
                      dense
                      outlined
                      :error-messages="errors"
                      label="Autodesk URN"
                    />
                  </ValidationProvider>
                  <v-checkbox v-model="siteView.isEvercamAccount" dense>
                    <template #label>
                      <span class="caption">
                        Using evercam autodesk account
                      </span>
                    </template>
                  </v-checkbox>
                </ECol>
              </ECol>
              <ECol cols="12" class="pt-0 pb-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Software types"
                  rules="required"
                >
                  <v-select
                    v-model="siteView.softwareType"
                    :items="softwareTypes"
                    label="Software types"
                    dense
                    outlined
                    :error-messages="errors"
                    item-value="value"
                    item-text="name"
                  />
                </ValidationProvider>
              </ECol>
            </ERow>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> Cancel </v-btn>
          <v-btn
            color="primary"
            text
            :disabled="invalid"
            @click="addSiteview()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>

<script>
import Autocomplete from "@evercam/shared/components/Autocomplete"

export default {
  components: {
    Autocomplete,
  },
  props: {
    getBIMCameras: {
      type: Function,
      default: () => ({}),
    },
  },
  data() {
    return {
      dialog: false,
      selectedProject: null,
      siteView: {
        projectId: null,
        softwareType: null,
        viewerType: null,
        projectName: null,
        cesiumId: null,
        cesiumId2D: null,
        cesiumDate: null,
        modelName: null,
        iTwinId: null,
        iModelId: null,
        urn: null,
        changeSetId: null,
        isIModel: null,
        height: null,
        markerDistanceThreshold: null,
        maximumScreenSpaceError: null,
      },
      defaultSiteView: {
        projectId: null,
        softwareType: null,
        viewerType: null,
        projectName: null,
        cesiumId: null,
        cesiumId2D: null,
        cesiumDate: null,
        modelName: "default",
        iTwinId: null,
        iModelId: null,
        urn: null,
        changeSetId: null,
        isIModel: null,
        height: null,
        markerDistanceThreshold: 700,
        maximumScreenSpaceError: 12,
        isEvercamAccount: true,
      },
      types: ["iTwin", "Cesium", "Forge"],
      softwareTypes: ["Navisworks", "Revit", "Synchro", "Drone"],
    }
  },
  watch: {
    async selectedProject(project) {
      if (project) {
        this.siteView.projectId = project.exid
        this.siteView.projectName = project.name
      }
    },
  },
  methods: {
    openDialog() {
      if (this.$refs.validator) {
        this.$refs.validator.reset()
      }
      this.siteView = { ...this.defaultSiteView }
      this.selectedProject = null
    },
    async addSiteview() {
      this.loading = true
      if (
        !(
          this.siteView.viewerType !== "iTwin" ||
          this.siteView.viewerType !== "Cesium" ||
          this.siteView.viewerType !== "Forge"
        )
      ) {
        throw new Error(
          "Viewer Type must be either ('iTwin' or 'Cesium' or 'Forge') !"
        )
      }
      const isExistingStatus = await this.isModelExists()
      if (isExistingStatus) {
        this.$notifications.warn(
          `This project already exists in ${this.siteView.viewerType}`
        )

        return
      }
      let siteView = {
        projectName: this.siteView.projectName,
        software: this.siteView.softwareType,
        type: this.siteView.viewerType,
        projectId: this.siteView.projectId,
        uniqueId: `${this.siteView.projectId}${this.siteView.viewerType}`,
      }
      let response = await fetch(
        this.$config.public.firebaseDbLink + "data/cameras-list.json"
      )
      const items = await response.json()
      items.push(siteView)
      fetch(this.$config.public.firebaseDbLink + "data/cameras-list.json", {
        method: "PUT",
        body: JSON.stringify({ ...items }),
      }).then(async () => {
        try {
          let data
          if (this.siteView.viewerType === "iTwin") {
            data = {
              models: {
                0: {
                  name: this.siteView.modelName,
                  iTwinId: this.siteView.iTwinId,
                  iModelId: this.siteView.iModelId,
                  changeSetId: this.siteView.changeSetId,
                  isIModel: this.siteView.isIModel,
                  height: parseInt(this.siteView.height),
                  cesiumId: +this.siteView.cesiumId,
                  markerDistanceThreshold:
                    +this.siteView.markerDistanceThreshold,
                },
              },
            }
          } else if (this.siteView.viewerType === "Cesium") {
            data = {
              models: {
                0: {
                  cesiumId: +this.siteView.cesiumId,
                  cesiumId2D: +this.siteView.cesiumId2D,
                  maximumScreenSpaceError:
                    +this.siteView.maximumScreenSpaceError,
                  date: this.siteView.cesiumDate,
                },
              },
            }
          } else if (this.siteView.viewerType === "Forge") {
            data = {
              urn: this.siteView.urn,
            }
          }
          await fetch(
            this.$config.public.firebaseDbLink +
              `data/projects/${this.siteView.viewerType.toLowerCase()}/${
                this.siteView.projectId
              }.json`,
            {
              method: "PUT",
              body: JSON.stringify(data),
            }
          )
          this.$notifications.success("Site view successfully saved")
        } catch (e) {
          this.$notifications.error("Couldn't save site view", e)
        } finally {
          this.getBIMCameras()
        }
      })

      this.loading = false
      this.dialog = false
    },
    async isModelExists() {
      let response = await fetch(
        this.$config.public.firebaseDbLink + "data/cameras-list.json"
      )
      const data = await response.json()

      return data.some(
        (item) =>
          item.projectId === this.siteView.projectId &&
          item.type === this.siteView.viewerType
      )
    },
  },
}
</script>
